/**
 * Internal Dependencies
 */
// Auth
import AuthSignIn from './AuthSignIn';
import AuthSignUp from './AuthSignUp';

// Start
import Dashboard from './Dashboard';
import Profile from "./Profile";

//Páginas
import CargosPage from "./Cargos";
import NovaVendaPage from "./NovaVenda";
import ColaboradoresPage from "./Colaboradores";
import MetasPage from "./Metas";
import ClientesPage from "./Clientes";
import EquipesPage from "./Equipes";
import PlanosPage from "./Planos";
import ProdutosPage from "./produto";
import DashboardSupervisao from "./DashboradSupervisao";
import LeadsPage from "./Leads";
import ShowVendaPage from "./NovaVenda/show";
import AcompanhamentoPage from "./Acompanhamento";
import PreVendaPage from "./PreVenda";
import DashboardMetas from "./DashboardMetas";
import MinhasNegociacoesPage from "./MinhasNegociacoes";
import DistribuicaoLeadsPage from "./Leads/distribuicao";
import MailingPage from "./mailing";
import UsuariosPage from "./Usuarios";
import ViabilidadePage from "./Viabilidade";
import ShowColaboradorEquipePage from "./Colaboradores/equipe";
import DashboardFinanceiro from "./DashboardFinanceiro";
import MeusLeadsPage from "./MeusLeads";
import CompartilhadosPage from "./Compartilhados";
import ComissionamentoPage from "./Financeiro/Comissionamento";
import CreditosPage from "./Financeiro/Creditos";
import DashboardOperador from "./DashboardOperador";
import ShowVendaBackofficePage from "./NovaVenda/backoffice";
import UpdateStatusVendasPage from "./UpdateStatusVendasPage";
import EditUsuariosPage from "./Usuarios/edit";
import EditProdutoPage from "./produto/edit";
import EditPlanosPage from "./Planos/edit";
import TrocarSenhaPage from "./TrocarSenha";
import EditEquipePage from "./Equipes/edit";
import BaseEnderecosPage from "./BaseEnderecos";

export default {
    '/sign-in': AuthSignIn,
    '/sign-up': AuthSignUp,

    '/': localStorage.getItem( 'account_type' ) === 1 ? Dashboard : ViabilidadePage,
    '/dash-supervisao': DashboardSupervisao,
    '/dash-metas': DashboardMetas,
    '/dash-financeiro': DashboardFinanceiro,
    '/dash-operador': DashboardOperador,
    '/dash-admin': Dashboard,
    '/profile': Profile,

    '/leads': LeadsPage,
    '/leads/distribuicao': DistribuicaoLeadsPage,
    '/mailing': MailingPage,
    '/viabilidade': ViabilidadePage,

    // Forms Base
    '/cargo': CargosPage,
    '/equipe': EquipesPage,
    '/colaborador': ColaboradoresPage,
    '/meta': MetasPage,
    '/cliente': ClientesPage,
    '/plano': PlanosPage,
    '/nova-venda': NovaVendaPage,
    '/produto': ProdutosPage,
    '/minhas-negociacoes': MinhasNegociacoesPage,
    '/meus-leads': MeusLeadsPage,
    '/vendas-compartilhadas': CompartilhadosPage,
    '/usuarios': UsuariosPage,

    '/totais-comissoes': ComissionamentoPage,
    '/totais-venda': CreditosPage,

    '/back-office': AcompanhamentoPage,
    '/pre-venda': PreVendaPage,

    //show
    '/nova-venda/show/:id': ShowVendaPage,
    '/venda/back-office/show/:id': ShowVendaBackofficePage,
    '/colaborador/equipe/:idUser': ShowColaboradorEquipePage,

    //edits
    '/usuarios/edit/:id': EditUsuariosPage,
    '/produto/edit/:id': EditProdutoPage,
    '/plano/edit/:id': EditPlanosPage,
    '/equipe/edit/:id': EditEquipePage,

    '/funcoes-administrativas': UpdateStatusVendasPage,

    '/trocar-senha': TrocarSenhaPage,

    '/base-enderecos': BaseEnderecosPage,

};
