/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
const jQuery = require( 'jquery' );

window.jQuery = jQuery;
window.$ = jQuery;

window.versao = '1.0.22';
window.yearNow = new Date().getFullYear();

const LOCAL_DOMAINS = [ "localhost", "127.0.0.1" ];

/*------------------------------------------------------------------
Conf
-------------------------------------------------------------------*/
class ROOTUI {}
window.RootUI = new ROOTUI();

if ( LOCAL_DOMAINS.includes( window.location.hostname ) ) {
    window.UrlAPI = `http://127.0.0.1:8000/api/`;
    window.UrlDB = `http://127.0.0.1:8000/db/`;
} else {
    //window.UrlAPI = `https://agilcrm.com.br/api/`;
    //window.UrlDB = `https://agilcrm.com.br/db/`;
    window.UrlAPI = `https://api-agil-homologacao.wifisulfibra.com.br/api/`;
    window.UrlDB = `https://api-agil-homologacao.wifisulfibra.com.br/db/`;
}
