import moment from "moment";

export const canUseDOM = !! (
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

/**
 * Check if email is valid.
 *
 * @param {string} email email string.
 *
 * @return {boolean} is valid.
 */
export function isValidEmail( email ) {
    // eslint-disable-next-line
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test( email );
}

/**
 * Get unique ID.
 *
 * @return {string} uid.
 */
export function getUID() {
    return (
        Number( String( Math.random() ).slice( 2 ) ) +
        Date.now() +
        Math.round( window.performance.now() )
    ).toString( 36 );
}

/**
 * Convert file size to human readable string.
 *
 * @param {number} size file size.
 *
 * @return {string} human readable size.
 */
export function fileSizeToHumanReadable( size ) {
    const i = Math.floor( Math.log( size ) / Math.log( 1024 ) );
    return ( ( size / Math.pow( 1024, i ) ).toFixed( 2 ) * 1 ) + ' ' + [ 'B', 'kB', 'MB', 'GB', 'TB' ][ i ];
}

export function convertData( data ) {
    const final = new Date( data );
    return final.toLocaleString( "pt-BR", { timeZone: "America/Sao_Paulo" } );
}

export function statusVenda( status ) {
    switch ( status ) {
    case 1 : return 'ABERTA';
    case 2 : return 'AGENDADO RETORNO';
    case 3 : return 'AGUARDANDO CADASTRAR';
    case 4 : return 'AGUARDANDO COMP PARA COMPLEMENTO';
    case 5 : return 'AGUARDANDO DOCUMENTAÇAO';
    case 6 : return 'AGUARDANDO TV';
    case 7 : return 'ANALISE DE CREDITO PENDENTE';
    case 8 : return 'AREA NAO LIBERADA';
    case 9 : return 'AUDITADA';
    case 10 : return 'BACKLIST';
    case 11 : return 'BAIXO SCORE';
    case 12 : return 'CADASTRAMENTO DE VIABILIDADE';
    case 13 : return 'CANCELADA';
    case 14 : return 'CANCELADO - LANÇAMENTO DUPLICADO';
    case 15 : return 'CLIENTE DESISTIU';
    case 16 : return 'CLIENTE INADIMPLENTE';
    case 17 : return 'CLIENTE OI OUTRO CPF';
    case 18 : return 'CLIENTE OUTRA OPERADORA';
    case 19 : return 'COM COBERTURA SEM PORTA';
    case 20 : return 'CONCLUIDO';
    case 21 : return 'CREDITO NEGADO';
    case 22 : return 'CTT DESCONHECE O CLIENTE';
    case 23 : return 'CTT NAO ATENDE';
    case 24 : return 'CTT NUMERO INEXISTENTE';
    case 25 : return 'DESISTENCIA';
    case 26 : return 'DEVOLVIDO';
    case 27 : return 'ENCAMINHADO PARA GC';
    case 28 : return 'ENVIADO AO GC';
    case 29 : return 'ERRO DE GERAÇÃO';
    case 30 : return 'FECHADO';
    case 31 : return 'INSTALADA';
    case 32 : return 'NAO TEM INTERESSE';
    case 33 : return 'PENDENTE';
    case 34 : return 'PORTABILIDADE';
    case 35 : return 'PRE VENDA';
    case 36 : return 'PRE VENDA DE PORTABILIDADE';
    case 37 : return 'PROPOSTA RECUSADA';
    case 38 : return 'REPROVADO NA ANALISE DE CREDITO';
    case 39 : return 'RESERVADO POR OUTRA REVENDA';
    case 40 : return 'RETORNO';
    case 41 : return 'ROUBO DE OS';
    case 42 : return 'SEGUNDA ANALISE';
    case 43 : return 'SEM COBERTURA';
    case 44 : return 'SIAC';
    case 45 : return 'SUSPEITA DE FRAUDE';
    case 46 : return 'VENDA';
    case 47 : return 'VENDA OUTRO CPF/CNPJ';
    case 48 : return 'VIABILIDADE PARCIAL';
    default : return 'Não informado';
    }
}

export function tipoUsuario( tipo ) {
    tipo = parseInt( tipo );
    switch ( tipo ) {
    case 1 : return 'Administrador';
    case 2 : return 'Supervisor';
    case 3 : return 'Operador';
    case 4 : return 'Back-office';
    default : return 'Não informado';
    }
}

export function faturamento( tipo ) {
    tipo = parseInt( tipo );
    switch ( tipo ) {
    case 1 : return 'Fatura em casa';
    case 2 : return 'Débito em conta';
    case 3 : return 'Cartão de crédito';
    case 4 : return 'Fatura via e-mail';
    default : return 'Não informado';
    }
}

export function statusVendaAuditoria( status ) {
    status = parseInt( status );
    switch ( status ) {
    case 1 : return 'PENDENTE';
    case 2 : return 'REALIZADA';
    default : return 'Não informado';
    }
}

export function statusCRUD( status ) {
    status = parseInt( status );
    switch ( status ) {
    case 1 : return 'Ativo';
    case 0 : return 'Inativo';
    default : return 'Não informado';
    }
}

export function Money( int ) {
    const numberPattern = /\d+/g;
    let tmp = int.match( numberPattern ).join( '' ) + '';
    tmp = tmp.replace( /([0-9]{2})$/g, ",$1" );
    if ( tmp.length > 6 )
        tmp = tmp.replace( /([0-9]{3}),([0-9]{2}$)/g, ".$1,$2" );

    return tmp;
}

export function dateTimeEnBr( data ) {
    return moment( data ).format( 'DD/MM/YYYY HH:mm' );
}

export function dateEnBr( data ) {
    return moment( data ).format( 'DD/MM/YYYY' );
}

export function removeHTML( html ) {
    if ( !! html ) {
        return html.replace( /<[^>]+>/g, '' );
    }
    return '...';
}

export function randomIntFromInterval( min, max ) { // min and max included
    return Math.floor( Math.random() * ( ( max - min + 1 ) + min ) );
}
