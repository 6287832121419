/**
 * External Dependencies
 */
import React, { Component } from 'react';
import 'animate.css';
/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageContent from '../../components/page-content';
import PageTitle from "../../components/page-title";
import axios from "axios";

/**
 * Component
 */
class DistribuicaoLeadsPage extends Component {
    getTableData = async function() {
        const self = this;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${ localStorage.getItem( 'token' ) }`,
            },
        };

        await axios.get( `${ window.UrlAPI }ws/lead/distribuir/${ localStorage.getItem( 'idSaas' ) }`, config ).then( res => {
            if ( res.status === 200 ) {
                self.setState( { data: res.data, isLoading: false } );
            }
        } ).catch( function( error ) {
            //self.setState( { isLoading: false } );
            console.log( error.status );
        } ).then( function() {
            self.setState( { isLoading: false } );
        } );
    };

    componentDidMount() {
        this.getTableData();
    }

    render() {
        return (
            <PageWrap>
                <PageTitle breadcrumbs={ {
                    '/': 'Home',
                    '/leads': 'Leads',
                } }>
                    <h1>Leads</h1>
                </PageTitle>
                <PageContent>
                    <h3>Início de distribuição agendando para iniciar em 60 segundos.  <span role="img" aria-label="donut">👽</span></h3>
                    <p>Atenção, ao iniciar a distribuição o servidor fará todo o processo de maneira automática, procure não iniciar várias distribuições de maneira simultânea.</p>
                </PageContent>
            </PageWrap>
        );
    }
}

export default DistribuicaoLeadsPage;
