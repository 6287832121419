/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal Dependencies
 */
import Icon from '../icon';

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const PagePopupSearch = ( props ) => {
    const {
        settings,
    } = props;

    return (
        <div className="rui-search">
            <div className="rui-search-head">
                <form action="#">
                    <div className="input-group">
                        <input type="search" className="form-control form-control-clean order-12" placeholder="Busca..." />
                        <div className="input-group-prepend mnl-3 order-1">
                            <button type="button" className="btn btn-clean btn-uniform btn-grey-5 mb-0 mnl-10">
                                <Icon name="search" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="rui-search-body">
                <div className="row vertical-gap">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h4>Perfil</h4>
                        <Link to="#" className="media media-success">
                            <span className="media-img"><img src={ settings.users[ 0 ].img } alt="" /></span>
                            <span className="media-body">
                                <span className="media-title">{ settings.users[ 0 ].name }</span>
                                <small className="media-subtitle">Administrador</small>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h4>Ajuda</h4>
                        <Link to="#" className="media">
                            <span className="media-img">L</span>
                            <span className="media-body">
                                <span className="media-title">Login</span>
                                <small className="media-subtitle">Como realizo login e logout?</small>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h4>Arquivos</h4>
                        <Link to="#" className="media">
                            <span className="media-img bg-transparent"><img src={ settings.img_file.zip } className="icon-file" alt="" /></span>
                            <span className="media-body">
                                <span className="media-title">ágilCRM</span>
                                <small className="media-subtitle">Nenhum arquivo cadastrado.</small>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PagePopupSearch;
