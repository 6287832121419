/**
 * External Dependencies
 */
import React, { Component } from 'react';
import 'animate.css';
/**
 * Internal Dependencies
 */
import AsyncComponent from '../../../components/async-component';
import PageWrap from '../../../components/page-wrap';
import PageContent from '../../../components/page-content';
import PageTitle from "../../../components/page-title";
import axios from "axios";

/**
 * Component
 */
class ComissionamentoPage extends Component {
    render() {
        return (
            <PageWrap>
                <PageTitle breadcrumbs={ {
                    '/': 'Home',
                    '/leads': 'Leads',
                    '/totais-comissoes': 'Comissionamento',
                } }>
                    <h1>Comissionamento</h1>
                </PageTitle>
                <PageContent>
                    <h3>Regras de comissão não definidas. <span role="img" aria-label="donut">😥</span></h3>
                </PageContent>
            </PageWrap>
        );
    }
}

export default ComissionamentoPage;
