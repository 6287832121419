/**
 * App Settings
 */
const accountType = localStorage.getItem( 'account_type' );

function checkLogin() {
    if ( accountType === 0 || accountType === undefined ) {
        return window.location.href = '/#/';
    }
}

checkLogin();

let settings = {
    night_mode: false,
};

switch ( accountType ) {
case '1' : {
    settings = {
        night_mode: false,
        spotlight_mode: false,
        show_section_lines: true,
        sidebar_dark: false,
        sidebar_static: false,
        sidebar_small: true,
        sidebar_effect: 'shrink',
        nav: true,
        nav_dark: false,
        nav_logo_path: require( '../../common-assets/images/logo.svg' ),
        nav_logo_white_path: require( '../../common-assets/images/logo-white.svg' ),
        nav_logo_width: '45px',
        nav_logo_url: '/',
        nav_align: 'left',
        nav_expand: 'lg',
        nav_sticky: true,
        nav_autohide: true,
        nav_container_fluid: true,
        home_url: '/',
        navigation: {
            '#actions': {
                name: 'Ações rápidas',
                icon: 'layers',
                sub: {
                    '/nova-venda': {
                        name: 'Nova venda',
                        icon: 'plus-circle',
                    },
                    '#manage-sites': {
                        name: 'Dashboard',
                        icon: 'sidebar',
                        sub: {
                            '/dash-admin': {
                                name: 'Administrativa',
                            },
                            '/dash-metas': {
                                name: 'Metas',
                            },
                        },
                    },
                },
            },
        },

        navigation_sidebar: {

            '/': {
                label: 'Dashboard',
                name: 'Visão',
                icon: 'home',
                sub: {
                    '/dash-admin': {
                        name: 'Administrativa',
                    },
                    '/dash-metas': {
                        name: 'Metas',
                    },
                },
            },

            '/totais-comissoes': {
                name: 'Comissionamento',
                icon: 'Percent',
            },

            // Content
            '/back-office': {
                label: 'Back-office',
                name: 'Acompanhamento',
                icon: 'briefcase',
            },

            // Content
            '/pre-venda': {
                name: 'Pré-venda',
                icon: 'file-plus',
            },

            // Content

            '/meta': {
                name: 'Metas',
                icon: 'target',
            },

            // Content
            '/viabilidade': {
                label: 'Viabilidade',
                name: 'Consulta por CEP',
                icon: 'check-circle',
            },

            // Content
            '/leads': {
                label: 'Leads',
                name: 'Leads',
                icon: 'user-check',
            },

            // Content
            '/base-enderecos': {
                label: 'Base endereços',
                name: 'Importar',
                icon: 'file-plus',
            },

            // Content
            '/negociacao': {
                label: 'Negociação',
            },
            '/cliente': {
                name: 'Clientes',
                icon: 'users',
            },
            '/nova-venda': {
                name: 'Vendas',
                icon: 'file-plus',
            },
            '/mailing': {
                name: 'Mailing',
                icon: 'mail',
            },
            '/minhas-negociacoes': {
                name: 'Minhas negociações',
                icon: 'star',
            },
            '/meus-leads': {
                name: 'Meus leads',
                icon: 'star',
            },
            '/vendas-compartilhadas': {
                name: 'Compartilhadas comigo',
                icon: 'star',
            },

            // Components Base
            '/component-alert': {
                label: 'Cadastros',
                name: 'Gerais',
                icon: 'layers',
                sub: {
                    '/cargo': {
                        name: 'Cargo',
                    },
                    '/equipe': {
                        name: 'Equipe',
                    },
                    '/colaborador': {
                        name: 'Colaborador',
                    },
                    '/plano': {
                        name: 'Planos',
                    },
                    '/produto': {
                        name: 'Produtos',
                    },
                    '/usuarios': {
                        name: 'Usuários',
                    },
                },
            },

            // Content
            '/funcoes-administrativas': {
                label: 'Administrativas',
                name: 'Atualização status',
                icon: 'check-circle',
            },

        },

        breadcrumbs_presets: {
            apps: {
                '/mailbox': 'Mailbox',
            },
        },
        img_country: {
            usa: require( '../../common-assets/images/flags/united-states-of-america.svg' ),
            spain: require( '../../common-assets/images/flags/spain.svg' ),
        },
        img_file: {
            empty: require( '../../common-assets/images/icon-empty.svg' ),
            zip: require( '../../common-assets/images/icon-zip.svg' ),
            rar: require( '../../common-assets/images/icon-rar.svg' ),
            html: require( '../../common-assets/images/icon-html.svg' ),
            php: require( '../../common-assets/images/icon-php.svg' ),
            css: require( '../../common-assets/images/icon-css.svg' ),
            js: require( '../../common-assets/images/icon-js.svg' ),
            doc: require( '../../common-assets/images/icon-doc.svg' ),
            txt: require( '../../common-assets/images/icon-txt.svg' ),
            pdf: require( '../../common-assets/images/icon-pdf.svg' ),
            xls: require( '../../common-assets/images/icon-xls.svg' ),
            png: require( '../../common-assets/images/icon-png.svg' ),
            jpg: require( '../../common-assets/images/icon-jpg.svg' ),
        },
        user: [
            {
                name: window.localStorage.getItem( 'name' ),
                email: window.localStorage?.getItem( 'email' ),
                id: window.localStorage.getItem( 'userId' ),
            },
        ],
        users: [
            {
                img: require( '../../common-assets/images/avatar-1.png' ),
                img_profile: require( '../../common-assets/images/avatar-1-profile.png' ),
                name: 'Administrador',
            },
        ],
        letters: [
            {
                img: require( '../../common-assets/images/letter-1.png' ),
                img_min: require( '../../common-assets/images/letter-1-min.png' ),
            },
        ],
    };
    break;
}
case '2' : {
    settings = {
        night_mode: false,
        spotlight_mode: false,
        show_section_lines: true,
        sidebar_dark: false,
        sidebar_static: false,
        sidebar_small: true,
        sidebar_effect: 'shrink',
        nav: true,
        nav_dark: false,
        nav_logo_path: require( '../../common-assets/images/logo.svg' ),
        nav_logo_white_path: require( '../../common-assets/images/logo-white.svg' ),
        nav_logo_width: '45px',
        nav_logo_url: '/',
        nav_align: 'left',
        nav_expand: 'lg',
        nav_sticky: true,
        nav_autohide: true,
        nav_container_fluid: true,
        home_url: '/',
        navigation: {
            '#actions': {
                name: 'Ações rápidas',
                icon: 'layers',
                sub: {
                    '/nova-venda': {
                        name: 'Nova venda',
                        icon: 'plus-circle',
                    },
                    '#manage-sites': {
                        name: 'Dashboard',
                        icon: 'sidebar',
                        sub: {
                            '/dash-metas': {
                                name: 'Metas',
                            },
                        },
                    },
                },
            },
        },

        navigation_sidebar: {

            '/': {
                label: 'Dashboard',
                name: 'Visão',
                icon: 'home',
                sub: {
                    '/dash-metas': {
                        name: 'Metas',
                    },
                },
            },

            // Content
            '/back-office': {
                label: 'Back-office',
                name: 'Acompanhamento',
                icon: 'briefcase',
            },

            // Content
            '/viabilidade': {
                label: 'Viabilidade',
                name: 'Consulta por CEP',
                icon: 'check-circle',
            },

            // Content
            '/leads': {
                label: 'Leads',
                name: 'Leads',
                icon: 'user-check',
            },

            // Content
            '/negociacao': {
                label: 'Negociação',
            },
            '/cliente': {
                name: 'Clientes',
                icon: 'users',
            },
            '/nova-venda': {
                name: 'Vendas',
                icon: 'file-plus',
            },
            '/mailing': {
                name: 'Mailing',
                icon: 'mail',
            },

            // Components Base
            '/component-alert': {
                label: 'Cadastros',
                name: 'Gerais',
                icon: 'layers',
                sub: {
                    '/cargo': {
                        name: 'Cargo',
                    },
                    '/equipe': {
                        name: 'Equipe',
                    },
                    '/colaborador': {
                        name: 'Colaborador',
                    },
                    '/plano': {
                        name: 'Planos',
                    },
                    '/produto': {
                        name: 'Produtos',
                    },
                    '/usuarios': {
                        name: 'Usuários',
                    },
                },
            },

        },

        breadcrumbs_presets: {
            apps: {
                '/mailbox': 'Mailbox',
            },
        },
        img_country: {
            usa: require( '../../common-assets/images/flags/united-states-of-america.svg' ),
            spain: require( '../../common-assets/images/flags/spain.svg' ),
        },
        img_file: {
            empty: require( '../../common-assets/images/icon-empty.svg' ),
            zip: require( '../../common-assets/images/icon-zip.svg' ),
            rar: require( '../../common-assets/images/icon-rar.svg' ),
            html: require( '../../common-assets/images/icon-html.svg' ),
            php: require( '../../common-assets/images/icon-php.svg' ),
            css: require( '../../common-assets/images/icon-css.svg' ),
            js: require( '../../common-assets/images/icon-js.svg' ),
            doc: require( '../../common-assets/images/icon-doc.svg' ),
            txt: require( '../../common-assets/images/icon-txt.svg' ),
            pdf: require( '../../common-assets/images/icon-pdf.svg' ),
            xls: require( '../../common-assets/images/icon-xls.svg' ),
            png: require( '../../common-assets/images/icon-png.svg' ),
            jpg: require( '../../common-assets/images/icon-jpg.svg' ),
        },
        user: [
            {
                name: window.localStorage.getItem( 'name' ),
                email: window.localStorage?.getItem( 'email' ),
                id: window.localStorage.getItem( 'userId' ),
            },
        ],
        users: [
            {
                img: require( '../../common-assets/images/avatar-1.png' ),
                img_profile: require( '../../common-assets/images/avatar-1-profile.png' ),
                name: 'Administrador',
            },
        ],
        letters: [
            {
                img: require( '../../common-assets/images/letter-1.png' ),
                img_min: require( '../../common-assets/images/letter-1-min.png' ),
            },
        ],
    };
    break;
}
case '3' : {
    settings = {
        night_mode: false,
        spotlight_mode: false,
        show_section_lines: true,
        sidebar_dark: false,
        sidebar_static: false,
        sidebar_small: true,
        sidebar_effect: 'shrink',
        nav: true,
        nav_dark: false,
        nav_logo_path: require( '../../common-assets/images/logo.svg' ),
        nav_logo_white_path: require( '../../common-assets/images/logo-white.svg' ),
        nav_logo_width: '45px',
        nav_logo_url: '/viabilidade',
        nav_align: 'left',
        nav_expand: 'lg',
        nav_sticky: true,
        nav_autohide: true,
        nav_container_fluid: true,
        home_url: '/viabilidade',
        navigation: {
            '#actions': {
                name: 'Ações rápidas',
                icon: 'layers',
                sub: {
                    '/nova-venda': {
                        name: 'Nova venda',
                        icon: 'plus-circle',
                    },
                    '#manage-sites': {
                        name: 'Dashboard',
                        icon: 'sidebar',
                        sub: {
                            '/dash-operador': {
                                name: 'Operador',
                            },
                        },
                    },
                },
            },
        },
        navigation_sidebar: {

            '/': {
                label: 'Dashboard',
                name: 'Visão',
                icon: 'home',
                sub: {
                    '/dash-operador': {
                        name: 'Operador',
                    },
                },
            },

            // Content
            '/viabilidade': {
                label: 'Viabilidade',
                name: 'Consulta por CEP',
                icon: 'check-circle',
            },
            // Content
            '/negociacao': {
                label: 'Negociação',
            },
            '/nova-venda': {
                name: 'Vendas',
                icon: 'file-plus',
            },
            '/minhas-negociacoes': {
                name: 'Minhas negociações',
                icon: 'star',
            },
            '/meus-leads': {
                name: 'Meus leads',
                icon: 'star',
            },
            '/vendas-compartilhadas': {
                name: 'Compartilhadas comigo',
                icon: 'star',
            },

        },

        breadcrumbs_presets: {
            apps: {
                '/mailbox': 'Mailbox',
            },
        },
        img_country: {
            usa: require( '../../common-assets/images/flags/united-states-of-america.svg' ),
            spain: require( '../../common-assets/images/flags/spain.svg' ),
        },
        img_file: {
            empty: require( '../../common-assets/images/icon-empty.svg' ),
            zip: require( '../../common-assets/images/icon-zip.svg' ),
            rar: require( '../../common-assets/images/icon-rar.svg' ),
            html: require( '../../common-assets/images/icon-html.svg' ),
            php: require( '../../common-assets/images/icon-php.svg' ),
            css: require( '../../common-assets/images/icon-css.svg' ),
            js: require( '../../common-assets/images/icon-js.svg' ),
            doc: require( '../../common-assets/images/icon-doc.svg' ),
            txt: require( '../../common-assets/images/icon-txt.svg' ),
            pdf: require( '../../common-assets/images/icon-pdf.svg' ),
            xls: require( '../../common-assets/images/icon-xls.svg' ),
            png: require( '../../common-assets/images/icon-png.svg' ),
            jpg: require( '../../common-assets/images/icon-jpg.svg' ),
        },
        user: [
            {
                name: window.localStorage.getItem( 'name' ),
                email: window.localStorage?.getItem( 'email' ),
                id: window.localStorage.getItem( 'userId' ),
            },
        ],
        users: [
            {
                img: require( '../../common-assets/images/avatar-1.png' ),
                img_profile: require( '../../common-assets/images/avatar-1-profile.png' ),
                name: 'Administrador',
            },
        ],
        letters: [
            {
                img: require( '../../common-assets/images/letter-1.png' ),
                img_min: require( '../../common-assets/images/letter-1-min.png' ),
            },
        ],
    };
    break;
}
case '4' : {
    settings = {
        night_mode: false,
        spotlight_mode: false,
        show_section_lines: true,
        sidebar_dark: false,
        sidebar_static: false,
        sidebar_small: true,
        sidebar_effect: 'shrink',
        nav: true,
        nav_dark: false,
        nav_logo_path: require( '../../common-assets/images/logo.svg' ),
        nav_logo_white_path: require( '../../common-assets/images/logo-white.svg' ),
        nav_logo_width: '45px',
        nav_logo_url: '/',
        nav_align: 'left',
        nav_expand: 'lg',
        nav_sticky: true,
        nav_autohide: true,
        nav_container_fluid: true,
        home_url: '/',
        navigation: {
            '#actions': {
                name: 'Ações rápidas',
                icon: 'layers',
                sub: {
                    '/nova-venda': {
                        name: 'Nova venda',
                        icon: 'plus-circle',
                    },
                    '#manage-sites': {
                        name: 'Dashboard',
                        icon: 'sidebar',
                        sub: {
                            '/dash-admin': {
                                name: 'Administrativa',
                            },
                        },
                    },
                },
            },
        },
        navigation_sidebar: {

            '/': {
                label: 'Dashboard',
                name: 'Visão',
                icon: 'home',
                sub: {
                    '/dash-admin': {
                        name: 'Administrativa',
                    },
                },
            },

            // Content
            '/back-office': {
                label: 'Back-office',
                name: 'Acompanhamento',
                icon: 'briefcase',
            },

            // Content
            '/viabilidade': {
                label: 'Viabilidade',
                name: 'Consulta por CEP',
                icon: 'check-circle',
            },

            // Content
            '/negociacao': {
                label: 'Negociação',
            },
            '/cliente': {
                name: 'Clientes',
                icon: 'users',
            },
            '/nova-venda': {
                name: 'Vendas',
                icon: 'file-plus',
            },

            // Content
            '/funcoes-administrativas': {
                label: 'Administrativas',
                name: 'Atualização status',
                icon: 'check-circle',
            },
        },
        breadcrumbs_presets: {
            apps: {
                '/mailbox': 'Mailbox',
            },
        },
        img_country: {
            usa: require( '../../common-assets/images/flags/united-states-of-america.svg' ),
            spain: require( '../../common-assets/images/flags/spain.svg' ),
        },
        img_file: {
            empty: require( '../../common-assets/images/icon-empty.svg' ),
            zip: require( '../../common-assets/images/icon-zip.svg' ),
            rar: require( '../../common-assets/images/icon-rar.svg' ),
            html: require( '../../common-assets/images/icon-html.svg' ),
            php: require( '../../common-assets/images/icon-php.svg' ),
            css: require( '../../common-assets/images/icon-css.svg' ),
            js: require( '../../common-assets/images/icon-js.svg' ),
            doc: require( '../../common-assets/images/icon-doc.svg' ),
            txt: require( '../../common-assets/images/icon-txt.svg' ),
            pdf: require( '../../common-assets/images/icon-pdf.svg' ),
            xls: require( '../../common-assets/images/icon-xls.svg' ),
            png: require( '../../common-assets/images/icon-png.svg' ),
            jpg: require( '../../common-assets/images/icon-jpg.svg' ),
        },
        user: [
            {
                name: window.localStorage.getItem( 'name' ),
                email: window.localStorage?.getItem( 'email' ),
                id: window.localStorage.getItem( 'userId' ),
            },
        ],
        users: [
            {
                img: require( '../../common-assets/images/avatar-1.png' ),
                img_profile: require( '../../common-assets/images/avatar-1-profile.png' ),
                name: 'Administrador',
            },
        ],
        letters: [
            {
                img: require( '../../common-assets/images/letter-1.png' ),
                img_min: require( '../../common-assets/images/letter-1-min.png' ),
            },
        ],
    };
    break;
}
default : settings = {
    night_mode: false,
    spotlight_mode: false,
    show_section_lines: true,
    sidebar_dark: false,
    sidebar_static: false,
    sidebar_small: true,
    sidebar_effect: 'shrink',
    nav: true,
    nav_dark: false,
    nav_logo_path: require( '../../common-assets/images/logo.svg' ),
    nav_logo_white_path: require( '../../common-assets/images/logo-white.svg' ),
    nav_logo_width: '45px',
    nav_logo_url: '/viabilidade',
    nav_align: 'left',
    nav_expand: 'lg',
    nav_sticky: true,
    nav_autohide: true,
    nav_container_fluid: true,
    home_url: '/viabilidade',
    navigation: {
        '#actions': {
            name: 'Ações rápidas',
            icon: 'layers',
            sub: {
                '/nova-venda': {
                    name: 'Nova venda',
                    icon: 'plus-circle',
                },
                '#manage-sites': {
                    name: 'Dashboard',
                    icon: 'sidebar',
                    sub: {
                        '/dash-operador': {
                            name: 'Operador',
                        },
                    },
                },
            },
        },
    },
    navigation_sidebar: {

        '/': {
            label: 'Dashboard',
            name: 'Visão',
            icon: 'home',
            sub: {
                '/dash-operador': {
                    name: 'Operador',
                },
            },
        },

        // Content
        '/viabilidade': {
            label: 'Viabilidade',
            name: 'Consulta por CEP',
            icon: 'check-circle',
        },
        // Content
        '/negociacao': {
            label: 'Negociação',
        },
        '/nova-venda': {
            name: 'Vendas',
            icon: 'file-plus',
        },
        '/minhas-negociacoes': {
            name: 'Minhas negociações',
            icon: 'star',
        },
        '/meus-leads': {
            name: 'Meus leads',
            icon: 'star',
        },
        '/vendas-compartilhadas': {
            name: 'Compartilhadas comigo',
            icon: 'star',
        },

    },
    breadcrumbs_presets: {
        apps: {
            '/mailbox': 'Mailbox',
        },
    },
    img_country: {
        usa: require( '../../common-assets/images/flags/united-states-of-america.svg' ),
        spain: require( '../../common-assets/images/flags/spain.svg' ),
    },
    img_file: {
        empty: require( '../../common-assets/images/icon-empty.svg' ),
        zip: require( '../../common-assets/images/icon-zip.svg' ),
        rar: require( '../../common-assets/images/icon-rar.svg' ),
        html: require( '../../common-assets/images/icon-html.svg' ),
        php: require( '../../common-assets/images/icon-php.svg' ),
        css: require( '../../common-assets/images/icon-css.svg' ),
        js: require( '../../common-assets/images/icon-js.svg' ),
        doc: require( '../../common-assets/images/icon-doc.svg' ),
        txt: require( '../../common-assets/images/icon-txt.svg' ),
        pdf: require( '../../common-assets/images/icon-pdf.svg' ),
        xls: require( '../../common-assets/images/icon-xls.svg' ),
        png: require( '../../common-assets/images/icon-png.svg' ),
        jpg: require( '../../common-assets/images/icon-jpg.svg' ),
    },
    user: [
        {
            name: window.localStorage.getItem( 'name' ),
            email: window.localStorage?.getItem( 'email' ),
            id: window.localStorage.getItem( 'userId' ),
        },
    ],
    users: [
        {
            img: require( '../../common-assets/images/avatar-1.png' ),
            img_profile: require( '../../common-assets/images/avatar-1-profile.png' ),
            name: 'Administrador',
        },
    ],
    letters: [
        {
            img: require( '../../common-assets/images/letter-1.png' ),
            img_min: require( '../../common-assets/images/letter-1-min.png' ),
        },
    ],
};
}

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace( /[?&]+([^=&]+)=([^&]*)/gi, ( a, name, value ) => {
    $_GET[ name ] = value;
} );

Object.keys( $_GET ).forEach( ( name ) => {
    const isTrue = $_GET[ name ] === '1';

    switch ( name ) {
    case 'setting-night-mode':
        settings.night_mode = isTrue;
        break;
    case 'setting-show-section-lines':
        settings.show_section_lines = isTrue;
        break;
    case 'setting-sidebar-small':
        settings.sidebar_small = isTrue;
        break;
    case 'setting-sidebar-dark':
        settings.sidebar_dark = isTrue;
        break;
    case 'setting-nav-dark':
        settings.nav_dark = isTrue;
        break;
        // no default
    }
} );

export default settings;
